import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import theme from '../theme';
import 'intersection-observer';
import 'firebase/storage';

import '../fonts/gt-eesti.css';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    background: ${props => props.dark ? '#000' : '#fff'};
    color: ${props => props.dark ? '#fff' : '#000'};
    font-family: 'GT Eesti', sans-serif;
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  #gatsby-focus-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .shaka-video {
      width: 100%;
      height: 100%;
  }

  .shaka-video-container {
    width: 100%;
    height: 100%;

    & .material-icons {
      font-size: 24px;
    }
  }
`;

const Layout = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>{children}</ModalProvider>
      <GlobalStyle dark={props.dark} />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
