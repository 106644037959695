import deepmerge from 'deepmerge';

import preset from '@rebass/preset';

const theme = deepmerge(preset, {
  colors: {
    green: '#026463',
    blue: '#161f2e',
    yellow: '#f6b91a',
  },
  forms: {
    label: {
      color: '#999',
    },  
    input: {
      color: '#aaa',
      borderColor: '#444',
      background: '#333',
      fontFamily: 'GT Eesti, sans-serif',
      fontSize: 16,
      padding: 11,
      lineHeight: 1,
      marginBottom: 0,
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: '#666',
      },
      '&::-webkit-input-placeholder': {
        color: '#666',
      },
      '&:disabled': {
        background: '#EEEEEE',
      },
    },
    consoleInput: {
      borderColor: '#aaa',
      fontFamily: 'GT Eesti, sans-serif',
      background: '#fff',
      '&:focus': {
        outline: 'none',
      },
    },
    textarea: {
      fontFamily: 'GT Eesti, sans-serif',
      borderColor: '#aaa',
      '&:focus': {
        outline: 'none',
      },
    },
    switch: {
      '&[aria-checked=true]': {
        backgroundColor: 'yellow',
      },
      '&:focus': {
        outline: 'none',
      },
      thumb: {
        borderColor: '#aaa'
      }
    }
  },
  buttons: {
    outline: {
      color: 'yellow',
      boxShadow: 'inset 0 0 0 1px',
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        color: '#aaa',
        cursor: 'not-allowed',
      },
    },
  },
});

export default theme;
